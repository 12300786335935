<template>
  <div class="pt-[66px] pb-[438px] mobile:pt-[28px] mobile:pb-[135px] mobile:px-[16px] flex flex-col gap-[40px] mobile:gap-[20px] items-center">
    <h1 class="text-[24px] font-[Suit-Bold] leading-[44px] w-full">나의 정보</h1>
    <div class="flex w-full gap-[40px] mobile:flex-col mobile:gap-[20px]">
      <div class="w-full flex flex-col gap-[30px] mobile:gap-[20px]">
        <MypageInput title="First Name" placeholder="이름을 입력해 주세요" v-model="firstName" :data="firstName" :validation="firstNameValidation" :style="{borderColor: firstNameValidation ? '#CBCBCB' : '#D1504B'}"/>
        <MypageInput title="Last Name" placeholder="성을 입력해 주세요" v-model="lastName" :data="lastName" :validation="lastNameValidation" :style="{borderColor: lastNameValidation ? '#CBCBCB' : '#D1504B'}"/>
        <MypageInput title="E-mail" placeholder="이메일을 입력해 주세요" v-model="email" :validation="emailValidation" :data="email" :style="{borderColor: emailValidation ? '#CBCBCB' : '#D1504B'}"/>

        <div class="flex flex-col gap-[4px] w-full">
          <p class="text-darkDark font-[Suit-Medium]  text-[16px]  leading-[28px]">Phone Num</p>
          <div class="flex flex-col gap-[10px]">
            <input class="text-[16px] border border-[1px] border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                   placeholder="연락처를 입력해 주세요" v-model="phone" @input="phoneHandle" :style="{borderColor: phoneValidation ? '#CBCBCB' : '#D1504B'}"
            />
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col gap-[30px] mobile:gap-[20px]">
        <div class="flex flex-col gap-[4px] w-full">
          <p class="text-darkDark font-[Suit-Medium]  text-[16px]  leading-[28px]" style="margin:0px;">ZIPCODE</p>
          <div class="flex flex-col gap-[10px]">
            <div class="flex w-full gap-[4px] justify-center items-center">
              <!--
              <input class="text-[16px] flex-grow border border-[1px] border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                     placeholder="우편번호를 입력하세요"
                     readonly
                     @click="searchZipCode"
                     v-model="zipCode"
              />:style="{borderColor: validator ? '#CBCBCB' : '#D1504B'}"
              <div @click="searchZipCode"
                   class="w-full flex-shrink-0 mobile:w-[115px] w-[150px] h-[52px] bg-[#A8221A] text-center text-white leading-[52px] cursor-pointer rounded-[5px]">
                우편번호 검색
              </div>
              -->
              <input class="text-[16px] border border-[1px] border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                   placeholder="우편 번호를 입력해주세요" v-model="zipCode" :validation="zipCodeValidation" :data="zipCode" :style="{borderColor: zipCodeValidation ? '#CBCBCB' : '#D1504B'}"
              />
            </div>
            <!--
            <input class="text-[16px] border border-[1px] border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                   placeholder="기본주소를 검색해주세요" readonly @click="searchZipCode"
                   v-model="defaultAddress"
            />
            -->
          </div>
          
        </div>
        <div class="flex flex-col gap-[4px] w-full">
            <p class="text-darkDark font-[Suit-Medium]  text-[16px]  leading-[28px]" style="margin:0px;">ADDRESS</p>
            <div class="flex flex-col gap-[10px]">
              <div class="flex w-full gap-[4px] justify-center items-center">
                <input class="text-[16px] border border-[1px] border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
                   placeholder="상세 주소를 입력해주세요" v-model="defaultAddress" :validation="addressValidation" :data="defaultAddress" :style="{borderColor: addressValidation ? '#CBCBCB' : '#D1504B'}"
                />
              </div>
            </div>
          </div>  
        <MypageInput title="개인통관번호" placeholder="P를 포함한 13자리를 입력해주세요" max-length="13" v-model="customerNumber" :validation="customerNumberValidation" :data="customerNumber" />
      </div>
    </div>

    <div @click="save()"
         class="mobile:mt-[20px] mobile:w-[calc(100%-32px)] mobile:w-full  w-[455px] h-[52px] bg-[#A8221A] text-center text-white leading-[52px] cursor-pointer rounded-[5px]">
      저장하기
    </div>


    <AlertModal
        :title="alertTitle"
        :content="alertContent"
        :pc-width="400"
        :button-class="'primary-btn'"
        :is-open="defaultAlertModalOpen"
        :confirm-click-btn="closeAlertModal"/>

    <AlertModal v-if="isOpenCompleteEditProfileAlert"
                title="수정완료"
                content="프로필 수정이 완료되었습니다"
                :pc-width="400"
                :button-class="'primary-btn'"
                :is-open="isOpenCompleteEditProfileAlert"
                :confirm-click-btn="closeCompleteAlert"/>

  </div>
</template>
<script>
import MypageInput from "@/components/mypage/MypageInput";

import getContractApi from "@/api/contract";
import AlertModal from "@/components/modals/AlertModal";
export default {
  components: {AlertModal, MypageInput},
  data(){
    return {
      walletAddress: null,
      firstName: null,
      lastName: null,
      email: null,
      zipCode: null,
      defaultAddress: null,
      detailAddress: null,
      customerNumber: null,
      phone: null,

      firstNameValidation: true,
      lastNameValidation: true,
      emailValidation: true,
      customerNumberValidation: true,
      phoneValidation: true,
      zipCodeValidation: true,
      addressValidation: true,

      showToast: false,


      defaultAlertModalOpen: false,
      alertTitle: '알림',
      alertContent: '준비중 입니다.',
      isOpenCompleteEditProfileAlert:false

    }
  },
  created() {
    this.market = getContractApi();
    if(this.$route.query.order) {
      this.nftOrder = this.$route.query.order
    }
  },
  mounted() {
    const _this = this;
    this.market.getWineMarket().getUserAddress().then(data => {
      if (data === undefined) {
        alert('메타마스크 연결을 확인해 주세요.')
        location.href = '/'
      } else {
        _this.walletAddress = data;
        this.$store.dispatch('userProfile/getUserProfile', {wallet_address: data}).then(res => {
          _this.firstName = res.first_name;
          _this.lastName = res.last_name;
          _this.email = res.email;
          _this.zipCode = res.zipcode;
          _this.defaultAddress = res.default_address;
          _this.detailAddress = res.detail_address;
          _this.customerNumber = res.customer_number;
          _this.phone = res.phone;

          // console.dir(res)
        })
      }

    })

  },
  methods:{
    /*
    searchZipCode: function () {
      let _this = this
      new window.daum.Postcode({
        oncomplete: function(data) {
          _this.zipCode = data.zonecode
          _this.defaultAddress = data.roadAddress
        }
      }).open();
    },
    */
    save(){
      if(this.validation()){
        const data = {
          'wallet_address': this.walletAddress,
          'first_name': this.firstName,
          'last_name': this.lastName,
          'email': this.email,
          'phone': this.phone,
          'zipcode': this.zipCode,
          'default_address': this.defaultAddress,
          // 'detail_address': this.detailAddress,
          'detail_address' : '',
          'customer_number': this.customerNumber,
          '_method': "put"
        }

        const formData = new FormData();
        formData.append('wallet_address', this.walletAddress);
        formData.append('first_name', this.firstName);
        formData.append('last_name', this.lastName);
        formData.append('email', this.email);
        formData.append('zipcode', this.zipCode);
        formData.append('default_address', this.defaultAddress);
        formData.append('detail_address', this.detailAddress);
        formData.append('customer_number', this.customerNumber);

        const _this = this;
        _this.$store.dispatch('userProfile/modifyUserProfile', data).then((/* res */) => {
          // console.dir(res)
          /*_this.$router.replace('/users/mypage/profile').then(() => {
            _this.showToast = true;
          })*/
          _this.isOpenCompleteEditProfileAlert = true;
        }).catch((err) => {
          console.dir(err)
        })

        // console.dir(data);
      }
      return 4;
    },
    validation() {
      let validator = true;
      const firstNameRegExp = /^[가-힣a-zA-Z]{1,}$/g;


      if(this.firstName){
        if(!firstNameRegExp.test(this.firstName)){
          this.firstNameValidation = false;
          this.openAlertModal('오류', '이름을 확인해 주세요');
          validator = false;
          return validator;
        }else{  
          this.firstNameValidation = true;
        }
      }
      else {
        this.firstNameValidation = false;
        this.openAlertModal('오류', '이름을 입력해 주세요');
        validator = false;
        return validator;
      }
      

      const lastNameRegExp = /^[가-힣a-zA-Z]{1,}$/g;
      if(this.lastName){
        if(!lastNameRegExp.test(this.lastName)){
          this.lastNameValidation = false;
          this.openAlertModal('오류', '성을 확인해 주세요');
          validator = false;
          return validator;
        }else{
          this.lastNameValidation = true;
        }
      }
      else {
        this.lastNameValidation = false;
        this.openAlertModal('오류', '성을 입력해 주세요');
        validator = false;
        return validator;
      }



      const emailRegExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      if(this.email) {
        if(!emailRegExp.test(this.email)){
          this.emailValidation = false;
          this.openAlertModal('오류', '이메일을 확인해 주세요');
          validator = false;
          return validator;
        }else{
          this.emailValidation = true;
        }
      }
      else {
        this.emailValidation = false;
        this.openAlertModal('오류', '이메일을 입력해 주세요');
        validator = false;
        return validator;
      }

      if(this.phone) {
        if(this.phone.replaceAll(/[^0-9]/g, '').length !== 11){
          this.phoneValidation = false;
          this.openAlertModal('오류', '연락처를 확인해 주세요');
          validator = false;
          return validator;
        }else{
          this.phoneValidation = true;
        }
      }
      else {
        this.phoneValidation = false;
        this.openAlertModal('오류', '연락처를 입력해 주세요');
        validator = false;
        return validator;
      }

      const customerNumberRegExp = /^(p|P)[0-9]{12}$/;
      if(this.customerNumber){
        if(!customerNumberRegExp.test(this.customerNumber)){
          this.customerNumberValidation = false;
          this.openAlertModal('오류', '개인 통관번호를 확인해 주세요');
          validator = false;
          return validator;
        }else{
          this.customerNumberValidation = true;
        }
      }
      else {
        this.customerNumberValidation = false;
        this.openAlertModal('오류', '개인 통관번호를 입력해 주세요');
        validator = false;
        return validator;
      }

      const zipCodeRegExp = /^[0-9]{1,10}$/;
      if(this.zipCode) {
        if(!zipCodeRegExp.test(this.zipCode)){
          this.zipCodeValidation = false;
          this.openAlertModal('오류', '우편번호를 확인해 주세요');
          validator=false;
          return validator;
        }
        else{
          this.zipCodeValidation = true;
        }
      }
      else {
        this.zipCodeValidation = false;
        this.openAlertModal('오류', '우편번호를 입력해 주세요');
        validator=false;
        return validator;
      }

      if(this.defaultAddress) {
        this.addressValidation = true;
      }
      else {
        this.addressValidation = false;
        this.openAlertModal('오류', '주소를 입력해 주세요');
        validator=false;
        return validator;
      }

      // console.dir(12341234124)
      // console.dir(!customerNumberRegExp.test(this.customerNumber))

      return validator;
    },
    phoneHandle(){
      this.phone = this.phone.replaceAll(/[^0-9]/g, '');
    },
    openAlertModal(title, content) {
      this.alertTitle = title
      this.alertContent = content
      this.defaultAlertModalOpen = true
    },
    closeAlertModal() {
      this.defaultAlertModalOpen = false
    },
    closeCompleteAlert(){
      this.$router.go(-1)
    }
  }
}
</script>