<template>
  <div class="flex flex-col gap-[4px] w-full">
    <p class="text-darkDark font-[Suit-Medium] text-[16px] leading-[28px]" style="margin:0;">{{ title }}</p>
    <input class="text-[16px] border border-lightLightDark rounded-[5px] h-[52px] w-full px-[16px] placeholder:text-lightLightDark"
           v-model="value"
        :placeholder="placeholder"
           :maxlength="max_length"
           @change="$emit('input', value)"
           :style="{borderColor: validator ? '#CBCBCB' : '#D1504B'}"
    />
  </div>
</template>
<script>
export default {
  props: ['title', 'placeholder', 'max_length', 'validation', 'data'],
  data(){
    return {
      value: this.data,
      validator: this.validation
    }
  },
  watch: {
    validation: function(newVal){
      this.validator = newVal;
    },
    data: function(newVal){
      this.value = newVal;
    }
  },
}
</script>